// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-index-js": () => import("./../src/templates/404/index.js" /* webpackChunkName: "component---src-templates-404-index-js" */),
  "component---src-templates-cart-index-js": () => import("./../src/templates/Cart/index.js" /* webpackChunkName: "component---src-templates-cart-index-js" */),
  "component---src-templates-checkout-index-js": () => import("./../src/templates/Checkout/index.js" /* webpackChunkName: "component---src-templates-checkout-index-js" */),
  "component---src-templates-confirmation-index-js": () => import("./../src/templates/Confirmation/index.js" /* webpackChunkName: "component---src-templates-confirmation-index-js" */),
  "component---src-templates-destination-index-js": () => import("./../src/templates/Destination/index.js" /* webpackChunkName: "component---src-templates-destination-index-js" */),
  "component---src-templates-home-index-js": () => import("./../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-payment-index-js": () => import("./../src/templates/Payment/index.js" /* webpackChunkName: "component---src-templates-payment-index-js" */),
  "component---src-templates-tour-combos-index-js": () => import("./../src/templates/TourCombos/index.js" /* webpackChunkName: "component---src-templates-tour-combos-index-js" */),
  "component---src-templates-tour-index-js": () => import("./../src/templates/Tour/index.js" /* webpackChunkName: "component---src-templates-tour-index-js" */)
}

