import React                  from 'react'
import AppHeader              from 'components/AppHeader'
import AppFooter              from 'components/AppFooter'
import CartHandler            from 'components/CartHandler'
// import BrowserUpdate          from 'components/BrowserUpdate'
// import TagManager             from 'react-gtm-module'
// import { hotjar }             from 'react-hotjar'
import styles                 from './styles.module.scss'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'css/bootstrap.min.css'
import 'css/fonts.scss'
import 'css/_global.scss'
import 'css/bootstrap-overwrite.scss'
import AnnouncementsComponent from '../AnnouncementsComponent';
import TokenExpiredModal      from 'components/TokenExpiredModal'

const App = ({ children, props }) => {

    // if(typeof window !== 'undefined') {
    //     if(window.location.hostname != 'localhost') {
    //         TagManager.initialize({
    //             gtmId: 'GTM-WVPCVP'
    //         })
    //         // Hotjar will be loaded from GTM
    //         ///hotjar.initialize(806135, 6)
    //     }
    // }

    return (
        <CartHandler>
            <div className={styles.root}>
                <AppHeader />
                <main>
                    {children}
                </main>
                <AppFooter />
                <AnnouncementsComponent />
                {/* <BrowserUpdate /> */}
                <TokenExpiredModal />
            </div>
        </CartHandler>
    )
}

export default App
