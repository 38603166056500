import React                from 'react'
import {
    graphql,
    useStaticQuery
}                           from 'gatsby'
import { map }              from 'lodash-es'
import {
    FaTwitter,
    FaFacebook,
}                           from 'react-icons/fa'
import {
    Col,
    Container,
    Row
}                           from 'react-bootstrap'
import Icon                 from 'entrada-ui/Icon'
import Link                 from 'components/Link'
import Logo                 from 'components/Logo'
import styles               from './styles.module.scss'

const FOOTER_MENU_QUERY = graphql`
{
    wordpressMenusMenusItems(name: {eq: "Footer menu"}) {
        items {
            title
            slug
            url
          }
    }
}
`

const AppFooter = (props) => {
    const {
        wordpressMenusMenusItems: {
            items
        }
    } = useStaticQuery(FOOTER_MENU_QUERY)

    return (
        <Container fluid className={styles.appFooter}>
            <Logo className={styles.logo} dark={true} />
                
            <div className={styles.subFooter}>
                <div className={styles.copy}>
                    &copy; 2016 - {new Date().getFullYear()} Entrada Travel Group
                </div>
                
                <div className={styles.links}>
                    <a href="https://entradatravelgroup.com/privacy-policy/">Privacy Policy</a> | <Link to="/terms-and-conditions"> Terms and Conditions </Link>
                </div>
            </div>
        </Container>
    )
}

export default AppFooter
