import React, { Component } from 'react'
import cx                   from 'utils/classnames'
import { map }              from 'lodash-es'
import RawHtml              from 'entrada-ui/RawHtml'
import styles               from './styles.module.scss'

import {
    FaBell,
    FaTimes,
}                           from 'react-icons/fa'

// import { graphql }                           from 'gatsby'
import { graphql, useStaticQuery } from "gatsby"

const ANNOUNCEMENT_QUERY = graphql`
{
    allWordpressWpAnnouncements {
        nodes {
            slug
            title
            content
        }
    }
}
`

const AnnouncementsComponent = (props) => {

    const {
        allWordpressWpAnnouncements: {
            nodes
        }
    } = useStaticQuery(ANNOUNCEMENT_QUERY)

    const hideAnnouncement = () => {
        setDisplayAnnouncement(false)
    }

    const [displayAnnouncement, setDisplayAnnouncement] = React.useState( nodes.length > 1 ? true : false )
    
    return (
        <>
          {displayAnnouncement && <div className={cx(styles.announceWrapper)}>
              <div className={cx(styles.announceBody,styles.wrapper)}>
                  <div className={cx(styles.closeWrap)}>
                        <button className={cx(styles.closeButton)} onClick={hideAnnouncement}>
                            <span className={cx(styles.icon)}>
                                <FaTimes />
                            </span>
                        </button>
                  </div>
                  <div className={cx(styles.announcement)}>
                    <span className={cx(styles.icon,styles.iconNotification)}>
                        <FaBell className={cx(styles.iconNotificationFa)} />
                    </span>
                    
                    {map(nodes, (node, idx) => {
                        return (
                            <div key={idx}>{node.slug !== "announcement_do_not_delete" ? <span key={idx}><RawHtml html={node.content} /></span>:<></>}</div>
                        )
                    })}
                  </div>
              </div>
          </div>}
        </>
    )
}

export default AnnouncementsComponent
